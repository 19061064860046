import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/Base'
import SEO from 'components/shared/SEO'
import tallPlant from  '../../../static/images/living-room/tall.jpeg'
import RoomPage from 'components/RoomPage'
import { formatNodes } from 'utils/formatNodes'

function LivingRoomPage({data}) {
	const recommended = formatNodes(data.recommendedQuery)
  const statementData = formatNodes(data.statementQuery)
  const shadeData = formatNodes(data.shadeQuery)
  const lightData = formatNodes(data.lightQuery)

  const pageData = {
    header: {
      title: "Plants for the Living Room",
      copy: "Bring a source of energy into the central room of your house.",
      image: data.bookshelf.childImageSharp.fluid
    },
    recommended: {
      title: "Top Picks",
      cta: {
        title: "View all living room plants",
        link: "/plants?kitchen"
      },
      items: recommended.slice(0,8)
    },
    sections: [
      {
        title: "Plants that make a statement",
        image: data.tallPlant.childImageSharp.fluid,
        items: statementData
      },
      {
        title: "Plants that thrive in the shade",
        image: data.bike.childImageSharp.fluid,
        items: shadeData
      },
      {
        title: "Plants that love light",
        image: data.carpet.childImageSharp.fluid,
        items: lightData
      }
    ]
  }
  return <RoomPage data={pageData}/>
}

export default ({ data, location }) => {
  return (
    <>
      <SEO title="Plants for the Living Room"/>
      <LivingRoomPage data={data}/>
    </>
  )
}

export const query = graphql`
  query LivingRoomQuery {
    bookshelf: file(relativePath: { eq: "living-room/bookshelf.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    carpet: file(relativePath: { eq: "living-room/carpet.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lightPlants: file(relativePath: { eq: "living-room/light.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bike: file(relativePath: { eq: "living-room/bike.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shadePlants: file(relativePath: { eq: "living-room/shade.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tallPlant: file(relativePath: { eq: "living-room/tall.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    recommendedQuery: allStripeSku(
      limit:18,
      filter: { metadata: { living_room: { in: "True" } } },
      sort: { fields: [metadata___ranking], order: ASC  } 
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    lightQuery: allStripeSku(filter: { metadata: { l_sun: { in: "True" } } } ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    statementQuery: allStripeSku(filter: { metadata: { l_statement: { in: "True" } } } ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
     shadeQuery: allStripeSku(filter: { metadata: { l_shade: { in: "True" } } } ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
  }
`